<template>
  <div>
    <products-page></products-page>
  </div>
</template>

<script>
import ProductsPage from '../components/ProductsPage.vue'

export default {
  name: "Products",
  components: { ProductsPage },

}
</script>
